.link {
  color: #000;
  text-decoration: none;
  cursor: pointer;
}
.link:hover {
  color: #0099cc;
  text-decoration: underline;
  cursor: pointer;
}
